export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBwtA1ck5v9fc0z8SXpTCpGkJCtKzyHpDc',
    authDomain: 'binder-20.firebaseapp.com',
    databaseURL: 'https://binder-20.firebaseio.com',
    projectId: 'binder-20',
    storageBucket: 'binder-20.appspot.com',
    messagingSenderId: '131081854199',
    appId: '1:131081854199:web:83982d54d146640f6847f2',
    measurementId: 'G-R4TD7FT00F',
  },
  baseUrl: 'https://dev.getbinder.net',
  fnBaseUrl: 'https://dev.getbinder.net/api/v1',
  bundleId: 'net.getbinder.dev',
  packageName: 'net.getbinder.dev',
  dynamicLinkDomain: 'dev.getbinder.net',
  localDbName: '__binder_dev',
  fnRegion: 'us-central-1',
  auth0: {
    clientID: 'NCfiND104vpDVWOT1kP092cV4P4I6XcV',
    domain: 'binder-20.auth0.com',
    responseType: 'token id_token',
    audience: 'https://binder-20.auth0.com/userinfo',
    redirectUri: 'http://localhost:8100/home',
    scope: 'openid'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 * This should also be commented out for cypress as it will throw zone related errors.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
