<ion-app>
  <ion-menu contentId="menuContent" type="push" side="start" persistent="true" #sidemenu>
    <ion-content>
      <ion-list id="menu-header">
        <ion-list-header>Menu</ion-list-header>
        <!--ion-note>hi@ionicframework.com</ion-note-->

        <ion-menu-toggle auto-hide="false">
          <ion-item routerLink="/home" (click)="sidemenu.close()">
            <ion-label>Home</ion-label>
          </ion-item>
          <ion-item routerLink="/cases" (click)="sidemenu.close()">
            <ion-label>Cases</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="menuContent"></ion-router-outlet>
</ion-app>
